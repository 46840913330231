import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

/**
 * Layout: Turkish
 * Source: Caglar Turali (https://github.com/caglarturali)
 */
export default <LayoutItem>{
  layout: {
    default: [
      '" 1 2 3 4 5 6 7 8 9 0 * - # {bksp}',
      'q w e r t y u ı o p ğ ü [ ]',
      'a s d f g h j k l ş i , {enter}',
      '{shift} < z x c v b n m ö ç . | $ €',
      '{space}',
    ],
    shift: [
      "é ! ' ^ + % & / ( ) = ? _ ~ {bksp}",
      'Q W E R T Y U I O P Ğ Ü { }',
      'A S D F G H J K L Ş İ ; {enter}',
      '{shift} > Z X C V B N M Ö Ç : \\ ` ´',
      '{space}',
    ],
  },
};
