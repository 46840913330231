import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

/**
 * Layout: Japanese
 */
export default <LayoutItem>{
  layout: {
    default: [
      '1 2 3 4 5 6 7 8 9 0 - ^ \u00a5 {bksp}',
      '\u305f \u3066 \u3044 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u309b \u309c \u3080',
      '\u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}',
      '{shift} \u3064 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u306d \u308b \u3081',
      '{space}',
    ],
    shift: [
      '! " # $ % & \' ( ) \u0301 = ~ | {bksp}',
      '\u305f \u3066 \u3043 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u300c \u300d \u3080',
      '\u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}',
      '{shift} \u3063 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u3001 \u3002 \u30fb',
      '{space}',
    ],
  },
};
