import ar from '@/components/virtual-keyboard/layouts/ar';
import de from '@/components/virtual-keyboard/layouts/de';
import en from '@/components/virtual-keyboard/layouts/en';
import es from '@/components/virtual-keyboard/layouts/es';
import fr from '@/components/virtual-keyboard/layouts/fr';
import hi from '@/components/virtual-keyboard/layouts/hi';
import it from '@/components/virtual-keyboard/layouts/it';
import ja from '@/components/virtual-keyboard/layouts/ja';
import ko from '@/components/virtual-keyboard/layouts/ko';
import nb from '@/components/virtual-keyboard/layouts/nb';
import pl from '@/components/virtual-keyboard/layouts/pl';
import ru from '@/components/virtual-keyboard/layouts/ru';
import sr from '@/components/virtual-keyboard/layouts/sr';
import sv from '@/components/virtual-keyboard/layouts/sv';
import th from '@/components/virtual-keyboard/layouts/th';
import tr from '@/components/virtual-keyboard/layouts/tr';
import uk from '@/components/virtual-keyboard/layouts/uk';
import vi from '@/components/virtual-keyboard/layouts/vi';
import zh from '@/components/virtual-keyboard/layouts/zh';

export type LayoutItem = {
  layout: { [layoutName: string]: string[] };
  layoutCandidates?: { [key: string]: string };
};

export const keyboardLayouts: Record<string, LayoutItem> = {
  ar,
  de,
  en,
  es,
  fr,
  hi,
  it,
  ja,
  ko,
  nb,
  pl,
  ru,
  sr,
  sv,
  th,
  tr,
  uk,
  vi,
  zh,
};
