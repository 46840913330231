import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

export default <LayoutItem>{
  layout: {
    default: [
      '\u0452 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '\u0459 \u0448 \u0435 \u0440 \u0442 \u0437 \u0443 \u0438 \u043E \u043F \u043B \u0458 \\',
      '\u0430 \u0441 \u0434 \u0444 \u0433 \u0445 \u045F \u043A \u043B \u0447 \u045B {enter}',
      '{shift} \u0436 \u0455 \u0446 \u0432 \u0431 \u043D \u043C \u0442 \u0434 .',
      '{space}',
    ],
    shift: [
      '\u0402 ! " # $ % & * ( ) _ + {bksp}',
      '\u0409 \u0428 \u0415 \u0420 \u0422 \u0417 \u0423 \u0418 \u041E \u041F \u041B \u0408 |',
      '\u0410 \u0421 \u0414 \u0424 \u0413 \u0425 \u040F \u041A \u041B \u0427 \u040B {enter}',
      '{shift} \u0416 \u0405 \u0426 \u0412 \u0411 \u041D \u041C \u0422 \u0414 ,',
      '{space}',
    ],
  },
};
