import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

export default <LayoutItem>{
  layout: {
    default: [
      '\u1EBB 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      'q w e r t y u i o p \u1EE7 \u0111 \\',
      'a s d f g h j k l \u00E0 \u00E2 {enter}',
      '{shift} z x c v b n m \u0103 \u00F4 .',
      '{space}',
    ],
    shift: [
      '\u00C9 ! @ # $ % ^ & * ( ) _ + {bksp}',
      'Q W E R T Y U I O P \u1EE6 \u0110 |',
      'A S D F G H J K L \u00C0 \u00C2 {enter}',
      '{shift} Z X C V B N M \u0102 \u00D4 ,',
      '{space}',
    ],
  },
};
