var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"03Z9HlphkmAJkgsZJHUTR"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { sentryConfig } from 'pxp-utils/helpers/sentry-config';
import { useHasPrivateMethodSupport } from './src/hooks/use-private-method-support';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const config = {
  beforeSend(event) {
    const isSupportedBrowser = useHasPrivateMethodSupport();
    return isSupportedBrowser ? event : null; // only send events for supported browsers
  },
};

Sentry.init(sentryConfig(SENTRY_DSN, config));
