import * as Portal from '@radix-ui/react-portal';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useOutsideClick } from 'pxp-utils/hooks/use-outside-click';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import type { SimpleKeyboard } from 'react-simple-keyboard/build/interfaces';
import 'react-simple-keyboard/build/css/index.css';

import { useVirtualKeyboardStore } from '@/hooks/use-virtual-keyboard';

import { useSSU } from '../../context/ssu-provider';
import { ElementSelector } from '../../enums/general';

import { keyboardLayouts } from './virtual-keyboard-layouts';
import css from './virtual-keyboard.module.scss';

export interface virtualKeyboardProps {
  isActiveOnInit?: boolean;
}

const display = {
  '{bksp}': '←',
  '{space}': 'SPACE',
  '{enter}': '↲',
  '{shift}': 'shift',
};

const themeName = 'hg-theme-default ssu-theme-dark';

const openKeyboardForTypes = ['search', 'text'];

const getKeyboardLayout = (language: string, layoutConfig: string[]) => {
  const languageSubstring = language?.substring(0, 2);
  return keyboardLayouts[languageSubstring] && layoutConfig.includes(language)
    ? keyboardLayouts[languageSubstring]
    : keyboardLayouts.en;
};

export const VirtualKeyboard: FC<virtualKeyboardProps> = ({
  isActiveOnInit = false,
}) => {
  const {
    config: { virtualKeyboard },
  } = useSSU();
  const isEnabled = virtualKeyboard?.isEnabled ?? false;
  const activeInput = useRef<HTMLInputElement | null>(null);
  const keyboardWrapper = useRef<HTMLDivElement | null>(null);
  const keyboard = useRef<SimpleKeyboard | null>(null);
  const { isActive, setIsActive, id } = useVirtualKeyboardStore();

  const classNames = classnames(css.root, isActive ? css.isActive : null);
  const { events } = useRouter();
  const { i18n } = useTranslation();
  const [keyboardLayout, setKeyboardLayout] = useState(keyboardLayouts.en);
  const [layoutName, setLayoutName] = useState('default');

  const resetKeyboard = () => {
    setIsActive(false);
    keyboard.current?.clearInput();
  };

  const onKeyBoardChange = (value: string) => {
    if (activeInput.current) {
      activeInput.current.value = value;
      activeInput.current?.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  const handleKeyPress = (key: string) => {
    if (key === '{shift}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    }
  };

  useOutsideClick(
    [keyboardWrapper, activeInput],
    resetKeyboard,
    'data-virtual-keyboard-include',
  );

  useEffect(() => {
    setIsActive(isEnabled && isActiveOnInit);

    const onInputFocus = () => {
      if (document.activeElement?.tagName === 'INPUT') {
        const activeElement = document.activeElement as HTMLInputElement;
        if (!openKeyboardForTypes.includes(activeElement.type)) {
          return;
        }

        activeInput.current = activeElement;
        keyboard.current?.setInput(activeElement.value);
        setIsActive(true);
      }
    };

    const onRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        resetKeyboard();
      }
    };

    if (isEnabled) {
      window.addEventListener('focus', onInputFocus, true);
      events.on('routeChangeStart', onRouteChange);
    }

    return () => {
      window.removeEventListener('focus', onInputFocus, true);
      events.off('routeChangeStart', onRouteChange);
    };
  }, [events, isEnabled, isActiveOnInit]);

  useEffect(() => {
    const layoutConfig = virtualKeyboard?.layouts ?? [];
    const newKeyboardLayout = getKeyboardLayout(i18n.language, layoutConfig);
    setKeyboardLayout(newKeyboardLayout);
  }, [i18n.language, virtualKeyboard?.layouts]);

  return isEnabled ? (
    <Portal.Root>
      <div
        className={classNames}
        ref={keyboardWrapper}
        data-selector={ElementSelector.KEYBOARD}
        id={id}
      >
        <Keyboard
          keyboardRef={(r: SimpleKeyboard) => (keyboard.current = r)}
          onChange={onKeyBoardChange}
          onKeyPress={handleKeyPress}
          layout={keyboardLayout.layout}
          layoutName={layoutName}
          layoutCandidates={keyboardLayout.layoutCandidates}
          display={display}
          theme={themeName}
        />
      </div>
    </Portal.Root>
  ) : null;
};
