import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

/**
 * Layout: Swedish
 * Source: wpressdev (https://github.com/wpressdev)
 */
export default <LayoutItem>{
  layout: {
    default: [
      '\u00A7 1 2 3 4 5 6 7 8 9 0 + \u00B4 {bksp}',
      'q w e r t y u i o p \u00E5 ¨',
      "a s d f g h j k l \u00F6 \u00E4 ' {enter}",
      '{shift} < z x c v b n m , . -',
      '{space}',
    ],
    shift: [
      '\u00B0 ! " # $ % & / ( ) = ? ` {bksp}',
      'Q W E R T Y U I O P \u00C5 ^',
      'A S D F G H J K L \u00D6 \u00C4 * {enter}',
      '{shift} > Z X C V B N M ; : _',
      '{space}',
    ],
  },
};
