import type { Flight } from 'pxp-flight-api-schema/types';

import { FlightStatus } from '../types/flight';

const statusMap: Record<string, FlightStatus> = {
  isAirBorn: FlightStatus.AIRBORNE,
  isEarly: FlightStatus.EARLY,
  isInRegion: FlightStatus.FLIGHT_INFORMATION_REGION,
  isLanded: FlightStatus.LANDED,
  isBaggageOnBelt: FlightStatus.FIRST_BAG_ON_BELT,
  isArrived: FlightStatus.ARRIVED,
  isDelayed: FlightStatus.DELAYED,
  isCancelled: FlightStatus.CANCELLED,
  isGateChanged: FlightStatus.GATE_CHANGED,
  isGateClosing: FlightStatus.GATE_CLOSING,
  isGateClosed: FlightStatus.GATE_CLOSED,
  isGateOpen: FlightStatus.GATE_OPEN,
  isBoarding: FlightStatus.BOARDING,
  isDeparted: FlightStatus.DEPARTED,
  isOnSchedule: FlightStatus.ON_SCHEDULE,
};

type ObjectWitStatus = {
  status: Flight['status'];
};

export const isAirBorn = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.AIRBORNE;

export const isEarly = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.EARLY;

export const isInRegion = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.FLIGHT_INFORMATION_REGION;

export const isLanded = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.LANDED;

export const isBaggageOnBelt = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.FIRST_BAG_ON_BELT;

export const isArrived = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.ARRIVED;

export const isDelayed = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.DELAYED;

export const isCancelled = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.CANCELLED;

export const isGateChanged = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.GATE_CHANGED;

export const isGateClosing = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.GATE_CLOSING;

export const isGateClosed = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.GATE_CLOSED;

export const isGateOpen = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.GATE_OPEN;

export const isBoarding = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.BOARDING;

export const isDeparted = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.DEPARTED;

export const isOnSchedule = ({ status }: ObjectWitStatus) =>
  status === FlightStatus.ON_SCHEDULE;

export const getFlightStatus: (
  flight: Flight,
) => Record<keyof typeof statusMap, boolean> = (flight: Flight) => ({
  isAirBorn: isAirBorn(flight),
  isEarly: isEarly(flight),
  isInRegion: isInRegion(flight),
  isLanded: isLanded(flight),
  isBaggageOnBelt: isBaggageOnBelt(flight),
  isArrived: isArrived(flight),
  isDelayed: isDelayed(flight),
  isCancelled: isCancelled(flight),
  isGateChanged: isGateChanged(flight),
  isGateClosing: isGateClosing(flight),
  isGateClosed: isGateClosed(flight),
  isGateOpen: isGateOpen(flight),
  isBoarding: isBoarding(flight),
  isDeparted: isDeparted(flight),
  isOnSchedule: isOnSchedule(flight),
});

const baseKey = 'general.flightInfo.status';
const flightStatusTranslationKeyMap: Partial<Record<Flight['status'], string>> =
  {
    AIR: `${baseKey}.airBorn`,
    EAR: `${baseKey}.early`,
    FIR: `${baseKey}.inRegion`,
    LND: `${baseKey}.landed`,
    FIB: `${baseKey}.baggageOnBelt`,
    ARR: `${baseKey}.arrived`,
    DEL: `${baseKey}.delayed`,
    CNX: `${baseKey}.cancelled`,
    GCH: `${baseKey}.gateChanged`,
    GCL: `${baseKey}.gateClosing`,
    GTD: `${baseKey}.gateClosed`,
    GTO: `${baseKey}.gateOpen`,
    BRD: `${baseKey}.boarding`,
    DEP: `${baseKey}.departed`,
    SCH: `${baseKey}.onSchedule`,
  };

export const getFlightStatusTranslationKey = (flight: Flight) => {
  return flightStatusTranslationKeyMap[flight.status];
};
