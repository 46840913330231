import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

export default <LayoutItem>{
  layout: {
    default: [
      '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
      'q w e r t z u i o p \u00FC +',
      'a s d f g h j k l \u00F6 \u00E4 # {enter}',
      '{shift} < y x c v b n m , .',
      '{space}',
    ],
    shift: [
      '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
      'Q W E R T Z U I O P \u00DC *',
      "A S D F G H J K L \u00D6 \u00C4 ' {enter}",
      '{shift} > Y X C V B N M ; : _',
      '{space}',
    ],
  },
};
