import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

/**
 * Layout: Ukrainian
 * Source: boomsya (https://github.com/boomsya)
 */
export default <LayoutItem>{
  layout: {
    default: [
      '\u0027 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '\u0439 \u0446 \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u0449 \u0437 \u0445 \u0457 \u0491 \\',
      '\u0444 \u0456 \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u0454 {enter}',
      '{shift} / \u044f \u0447 \u0441 \u043c \u0438 \u0442 \u044c \u0431 \u044e .',
      '{space}',
    ],
    shift: [
      '\u20B4 ! " \u2116 ; % : ? * ( ) _ + {bksp}',
      '\u0419 \u0426 \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u0429 \u0417 \u0425 \u0407 \u0490 /',
      '\u0424 \u0406 \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u0404 {enter}',
      '{shift} | \u042f \u0427 \u0421 \u041c \u0418 \u0422 \u042c \u0411 \u042e ,',
      '{space}',
    ],
  },
};
