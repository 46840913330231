import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

/**
 * Layout: Polish
 * Source: maciej-sielski (https://github.com/maciej-sielski)
 */
export default <LayoutItem>{
  layout: {
    default: [
      "\u02DB 1 2 3 4 5 6 7 8 9 0 + ' {bksp}",
      'q w e r t z u i o p \u017C \u015B',
      'a s d f g h j k l \u0142 \u0105 \u00F3 {enter}',
      '{shift} < y x c v b n m , . -',
      '{space}',
    ],
    shift: [
      '\u00B7 ! " # \u00A4 % & / ( ) = ? * {bksp}',
      'Q W E R T Z U I O P \u0144 \u0107',
      'A S D F G H J K L \u0141 \u0119 \u017A {enter}',
      '{shift} > Y X C V B N M ; : _',
      '{space}',
    ],
  },
};
