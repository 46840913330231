import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

/**
 * Layout: French
 */
export default <LayoutItem>{
  layout: {
    default: [
      '` 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}',
      'a z e r t y u i o p ^ $',
      'q s d f g h j k l m \u00F9 * {enter}',
      '{shift} < w x c v b n , ; : !',
      '{space}',
    ],
    shift: [
      '\u00B2 & \u00E9 " \' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp}',
      'A Z E R T Y U I O P \u00A8 \u00A3',
      'Q S D F G H J K L M % \u00B5 {enter}',
      '{shift} > W X C V B N ? . / \u00A7',
      '{space}',
    ],
  },
};
