import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

export default <LayoutItem>{
  layout: {
    default: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}',
      '\u0636 \u0635 \u062B \u0642 \u0641 \u063A \u0639 \u0647 \u062E \u062D \u062C \u062F',
      '\u0634 \u0633 \u064A \u0628 \u0644 \u0627 \u062A \u0646 \u0645 \u0643 \u0637 {enter}',
      '{shift} \u0626 \u0621 \u0624 \u0631 \u0644\u0627 \u0649 \u0629 \u0648 \u0632 \u0638 , . ?',
      '{space}',
    ],
  },
};
