import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import useSWR from 'swr';

export interface GlobalSettingsData {
  isPublic: boolean;
}

export interface GlobalSettingsContext {
  settings: GlobalSettingsData;
}

const defaultGlobalSettingsData = {
  isPublic: false,
} satisfies GlobalSettingsData;

const defaultGlobalSettings = {
  settings: defaultGlobalSettingsData,
} satisfies GlobalSettingsContext;

const GlobalSettingsContext = createContext<GlobalSettingsContext>(
  defaultGlobalSettings,
);

export const GlobalSettingsProvider = ({ children }: PropsWithChildren) => {
  const {
    data: settingsFromServer,
    isLoading,
    error,
  } = useSWR<GlobalSettingsData>('/api/globalSettings', (url: string) =>
    fetch(url).then((res) => res.json()),
  );

  const value = useMemo(() => {
    return {
      settings:
        !isLoading && !error && settingsFromServer
          ? settingsFromServer
          : defaultGlobalSettingsData,
    };
  }, [error, isLoading, settingsFromServer]);

  return (
    <GlobalSettingsContext.Provider value={value}>
      {children}
    </GlobalSettingsContext.Provider>
  );
};

export function useGlobalSettings() {
  const context = useContext(GlobalSettingsContext);
  if (!context) {
    throw new Error(
      'useGlobalSetting must be used within GlobalsSettingsProvider',
    );
  }
  return context.settings;
}

GlobalSettingsContext.displayName = 'GlobalSettingsContext';
