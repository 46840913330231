import type { LayoutItem } from '@/components/virtual-keyboard/virtual-keyboard-layouts';

/**
 * Layout: Italian
 */
export default <LayoutItem>{
  layout: {
    default: [
      "\\ 1 2 3 4 5 6 7 8 9 0 ' \u00EC {bksp}",
      'q w e r t y u i o p \u00E8 +',
      'a s d f g h j k l \u00F2 \u00E0 \u00F9 {enter}',
      '{shift} < z x c v b n m , . -',
      '{space}',
    ],
    shift: [
      '| ! " \u00A3 $ % & / ( ) = ? ^ {bksp}',
      'Q W E R T Y U I O P \u00E9 *',
      'A S D F G H J K L \u00E7 \u00B0 \u00A7 {enter}',
      '{shift} > Z X C V B N M ; : _',
      '{space}',
    ],
  },
};
