import { ErrorBoundary } from '@sentry/nextjs';
import Head from 'next/head';
import type { UserConfig } from 'next-i18next';
import { appWithTranslation } from 'next-i18next';
import React from 'react';

import { ConnectedToWifiToast } from '@/components/connected-to-wifi-toast/connected-to-wifi-toast';
import { OutOfServiceMessage } from '@/components/out-of-service-message/out-of-service-message';
import { TheMap } from '@/components/the-map/the-map';
import { GlobalSettingsProvider } from '@/context/global-settings-provider';
import { ModalProvider } from '@/context/modal-provider';
import { useHasPrivateMethodSupport } from '@/hooks/use-private-method-support';
import { isPublic } from '@/lib/environment';

import userConfig from '../../next-i18next.config';
import { BoardingPassScanner } from '../components/boarding-pass-scanner/boarding-pass-scanner';
import { CssVarsProvider } from '../components/css-vars-provider/css-vars-provider';
import { ErrorBareFallback } from '../components/error-fallback/error-bare-fallback';
import { ErrorFallback } from '../components/error-fallback/error-fallback';
import { FontProvider } from '../components/font-provider/font-provider';
import {
  type EventMap,
  GlobalShortcutsEffects,
} from '../components/global-shortcuts-effects/global-shortcuts-effects';
import { HealthCheck } from '../components/health-check/health-check';
import { I18nEffects } from '../components/i18n-provider/i18n-effects';
import { NetworkListener } from '../components/network-state/network-state';
import { OfflineMessage } from '../components/offline-message/offline-message';
import { OutOfService } from '../components/out-of-service/out-of-service';
import { SessionTimeout } from '../components/session-timeout/session-timeout';
import { SsuHistory } from '../components/ssu-history/ssu-history';
import { ToastContainer } from '../components/toasts/toast-container';
import { VirtualKeyboard } from '../components/virtual-keyboard/virtual-keyboard';
import { APP_VERSION } from '../constants/app';
import { IconSetProvider } from '../context/icon-set-provider';
import { SSUProvider } from '../context/ssu-provider';
import { ToastProvider } from '../context/toast-context';
import { usePreventRightClick } from '../hooks/use-prevent-right-click';
import type { LayoutName } from '../layouts/get-layout';
import { getLayout as getLayoutFallback } from '../layouts/get-layout';
import { createPageTitle } from '../lib/title';
import { VirtualPageTracker } from '../modules/analytics/components/virtual-page-tracker/virtual-page-tracker';
import { DataLayerProvider } from '../modules/analytics/data-layer-provider';
import { Csat } from '../modules/csat';
import { FlightInfoModuleProvider } from '../modules/flight-info/context/flight-info-module-provider';
import type { AppPageProps, AppPropsWithLayout } from '../types/app';
import '../styles/globals.scss';
import '../styles/public.scss';

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { ssu } = pageProps as AppPageProps;
  const isSupportedBrowser = useHasPrivateMethodSupport();

  usePreventRightClick();

  if (!isSupportedBrowser) {
    return (
      <OutOfServiceMessage
        title="Unsupported browser"
        text="It looks like you’re using an outdated browser that is no longer
            supported. To ensure the best experience and security, please update
            your browser to the latest version."
      />
    );
  }

  if (!ssu) {
    return (
      <ErrorBoundary fallback={<ErrorBareFallback />}>
        <Component {...pageProps} />
      </ErrorBoundary>
    );
  }

  const getLayout = Component.getLayout ?? getLayoutFallback;
  const layout = getLayout(
    <Component {...pageProps} />,
    Component.layoutName as LayoutName,
  );

  const globalShortcuts: EventMap = {
    F9: 'START_VIDEO_CALL',
  };
  return (
    <ErrorBoundary fallback={<ErrorBareFallback />}>
      <GlobalSettingsProvider>
        <DataLayerProvider>
          <SSUProvider ssu={ssu}>
            <ErrorBoundary fallback={<ErrorFallback />}>
              <Head>
                <title>{createPageTitle(ssu)}</title>
                <meta name="version" content={APP_VERSION} />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
              </Head>
              <OutOfService />
              <I18nEffects />
              <VirtualKeyboard />
              <FontProvider />
              <CssVarsProvider />
              <FlightInfoModuleProvider>
                <GlobalShortcutsEffects eventMap={globalShortcuts} />
                <IconSetProvider>
                  <ToastProvider>
                    <ModalProvider>
                      {layout}
                      <ConnectedToWifiToast />
                      <ToastContainer />
                      <OfflineMessage />
                      <Csat />
                      <SessionTimeout />
                      <BoardingPassScanner />
                      <TheMap /> {/* preload the map */}
                    </ModalProvider>
                  </ToastProvider>
                </IconSetProvider>
              </FlightInfoModuleProvider>
              <SsuHistory />
              <NetworkListener />
              {!isPublic && <HealthCheck />}
              <VirtualPageTracker />
            </ErrorBoundary>
          </SSUProvider>
        </DataLayerProvider>
      </GlobalSettingsProvider>
    </ErrorBoundary>
  );
}

// eslint-disable-next-line -- This is a valid export
// @ts-ignore
export default appWithTranslation(MyApp, userConfig as UserConfig);
